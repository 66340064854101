<template>
  <main class="referrer-page container">
    <h1 class="title font font_title-l font_bold font_uppercase">
      {{ name }}

      <span v-if="productsCountPhrase" class="referrer-page__products-count">
        {{ productsCountPhrase }}
      </span>
    </h1>

    <LazyLoader
      v-if="products && products.length"
      :loading="isProductsPending"
      :next-page="canGetNextPage"
      class="catalog-page__products"
      @update="onLoaderUpdate"
    >
      <ul class="product-grid__list profile-promo__products-item">
        <li v-for="product in products" :key="product.id" class="product-grid__item profile-promo__products-item">
          <ProductCard :item="product" />
        </li>
      </ul>
    </LazyLoader>
  </main>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import '@/components/catalog/productsLazyLoad/index.css';

import { phraseDeclension } from '@/plugins/formatting';

import LazyLoader from '@/components/elements/LazyLoader';
import ProductCard from '@/components/catalog/productCard';
import pageMeta from '~/mixins/pageMeta';

export default {
  name: 'ReferrerPromoPage',
  components: {
    LazyLoader,
    ProductCard,
  },
  mixins: [pageMeta],
  async asyncData({ $api, route }) {
    const referralCode = route.params.code
    const { id, name } = await $api.profile.getPromopageInfo(referralCode);

    const response = await $api.profile.getByIdPromopageProducts({ id });

    return {
      pageTitle: name,
      id,
      name,
      referralCode,

      products: response?.products || [],
      productsCount: response?.products_count || 0,
    };
  },
  data() {
    return {
      isProductsPending: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    canGetNextPage() {
      return this.products.length < this.productsCount;
    },
    productsCountPhrase() {
      return phraseDeclension(this.productsCount, ['продукт', 'продуктов', 'продукта']);
    },
  },
  mounted() {
    this.referralCode && this.setReferralSession(this.referralCode)
  },
  methods: {
    ...mapActions({
      setReferralSession: 'auth/setReferralSession',
    }),
    onLoaderUpdate() {
      this.isPending = true;
      this.$emit('get-items');
    },
  },
};
</script>
