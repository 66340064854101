<template>
  <div class="product-posters default-slider _swiper" :class="sliderClass">
    <div
      v-swiper:sliderPosters="sliderOptions"
    >
      <ul class="swiper-wrapper product-posters__wrapper">
        <li
          v-for="(poster, index) in posters"
          :key="index"
          class="swiper-slide product-posters__item-wrapper"
        >
          <div class="product-posters__item">
            <CustomImage
              class="product-card__img"
              :image="poster.url"
              :size="size"
              :alt="poster?.caption || `Постер ${index}`"
              :aspect-ratio="aspectRatio"
              :loading="index >= 1 ? 'lazy' : loading"
            />
          </div>
        </li>
      </ul>
      <div class="product-posters__controls">
        <div
          v-for="(poster, index) in posters"
          :key="index"
          class="product-posters__controls-item"
          @mouseenter="onControlHover(index)"
        ></div>
      </div>
      <div
        v-if="posters.length > 1"
        class="product-posters__dots"
        @click.stop
      ></div>
    </div>
  </div>
</template>

<script>
import {directive} from 'vue-awesome-swiper';
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'ProductCardPosters',
  components: {CustomImage},
  directives: {
    swiper: directive,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    posters: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    aspectRatio: {
      type: String,
      default: '100%',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs';
    },
    sliderClass() {
      return `product-posters_${this.id}`
    },
    sliderOptions() {
      return {
        observer: true,
        observeParents: true,
        spaceBetween: 0,
        centeredSlides: false,
        freeMode: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        loop: false,
        preloadImages: false,
        allowTouchMove: this.mqIsMobile,
        pagination: {
          el: `.product-posters_${this.id}  .product-posters__dots`,
          clickable: true,
        },
      }
    },
  },
  methods: {
    onControlHover(id) {
      if (!this.sliderPosters) return

      this.sliderPosters.slideTo(id, 0)
    },
  },
}
</script>
