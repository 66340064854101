<template>
  <article
    class="product-card"
    :class="{
      'product-card_tall' : tall,
      'product-card_min' : minified,
      'product-card-favorites': pageType === 'favorites',
    }"
  >
    <div v-if="!minified" class="product-card__header">
      <ProductCardBadges :badges="item.badges" />
      <div class="product-card__actions">
        <button
          v-if="pageType === 'catalog'"
          class="product-card__favorite"
          :class="{
            'product-card__favorite--active': productInFavorites(item.productId)
          }"
          aria-label="В избранное"
          data-test="product-card-add-to-favorite"
          @click="toggleToFavoritesItem"
        >
          <SvgIcon
            class="product-card__favorite-ico"
            :name="productInFavorites(item.productId) ? 'cat-fav-filled' : 'cat-fav'"
            width="24"
            height="24"
          />
        </button>
        <button
          v-if="pageType === 'promo'"
          class="product-card__remove-from-promo"
          aria-label="Удалить с промостраницы"
          data-test="product-card-remove-from-promo"
          @click="removeFromPromo"
        >
          <svg-icon name="close" width="16" height="16" />
        </button>
        <Button
          v-if="pageType === 'catalog'"
          :theme="cardBtn.theme"
          class="product-card__btn no-padding-btn product-card__cart"
          :class="{
            'product-card__cart--active': cardBtn.inCart
          }"
          :disabled="cardBtn.disabled"
          :round="!cardBtn.name"
          @click="cardBtn.onClick"
        >
          <SvgIcon v-if="cardBtn.icon" class="product-card__favorite-ico" :name="cardBtn.icon" width="24" height="24" />
          {{ cardBtn.name }}
        </Button>
      </div>
    </div>
    <nuxt-link
      class="product-card__link"
      :to="{
        name: 'catalog.category.product',
        params: {
          category: categoryLink,
          product: item.code,
        }
      }"
      @click.native="clickItemEvent"
    >
      <div class="product-card__img-wrapper">
        <CustomImage
          v-if="!productPosters.length"
          class="product-card__img"
          :image="item.image.url"
          size="small"
          retina="medium"
          :alt="item.image.caption || item.name"
          :aspect-ratio="tall ? '133.24%' : '100%'"
          :loading="loading"
        />
        <ProductCardPosters
          v-else
          :id="`${categoryLink}-${item.code}`"
          :posters="productPosters"
          :aspect-ratio="tall ? '133.24%' : '100%'"
          :loading="loading"
        />
      </div>
      <div class="product-card__brand">
        {{ item.brandName }}
      </div>
      <div class="product-card__body">
        <h3 class="product-card__name product-card__name_cutted font font_center">
          {{ item.name }}
        </h3>
      </div>
      <div class="product-card__info">
        <div
          class="product-card__price font"
          :class="{
            'font_title-l': pageType === 'favorites',
            'font_m': pageType !== 'favorites',
          }"
        >
          <template v-if="item.price">
            <div class="product-card__price-wrapper">
              <template v-if="item.price.value && item.price.value.from">
                <div
                  v-if="item.oldPrice && item.oldPrice.value && item.oldPrice.value.from"
                  class="product-card__price-old font font_grey"
                  :class="{
                    'font_xs': mqIsMobile,
                    'font_m': !mqIsMobile,
                  }"
                >
                  от {{ getCurrency(item.oldPrice.value.from) }}
                </div>
                <div
                  :class="{
                    'font_medium font_xs': mqIsMobile,
                    'font_bold font_m': !mqIsMobile,
                    'product-card__price_sale': item.oldPrice && item.oldPrice.value.from
                  }"
                >
                  от {{ getCurrency(item.price.value.from) }}
                </div>
              </template>
              <template v-else-if="item.price.value">
                <div
                  v-if="item.oldPrice && item.oldPrice.value"
                  class="product-card__price-old font font_grey"
                  :class="{
                    'font_xs': mqIsMobile,
                    'font_m': !mqIsMobile,
                  }"
                >
                  {{ getCurrency(item.oldPrice.value) }}
                </div>
                <div
                  :class="{
                    'font_medium font_xs': mqIsMobile,
                    'font_medium font_m': !mqIsMobile,
                    'product-card__price_sale': item.oldPrice && item.oldPrice.value
                  }"
                >
                  {{ getCurrency(item.price.value) }}
                </div>
              </template>
              <div
                v-else
                :class="{
                  'font_xs font_medium': mqIsMobile,
                  'font_m font_medium': !mqIsMobile,
                }"
              >
                {{ getCurrency(item.price) }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </nuxt-link>
    <div class="product-card-add-to-cart-favorites-container">
      <Button
        v-if="pageType === 'favorites'"
        aria-label="Удалить из избранного"
        class="product-card-add-to-cart-favorites"
        data-test="product-card-remove-from-favorites"
        @click="toggleToFavoritesItem"
      >
        <SvgIcon name="bin" width="20" height="20" />
      </Button>
      <Button
        v-if="pageType === 'favorites' && !productInCart(item.productId)"
        class="product-card-add-to-cart-favorites"
        :class="{
          'product-card__cart--active': cardBtn.inCart
        }"
        @click="onAddCartButtonClick"
      >
        <svg-icon
          v-if="cardBtn.icon"
          :name="cardBtn.icon"
          width="32"
          height="32"
        />
      </Button>
    </div>
    <Button
      v-if="pageType === 'favorites' && productInCart(item.productId)"
      class="product-card-add-to-cart-favorites product-card-in-cart"
      @click="handleGoToCart"
    >
      <svg-icon
        class="add-to-cart-icon"
        name="site-header/cart-small-white"
        width="16"
        height="16"
      /> В корзине
    </Button>
  </article>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import * as amplitudeTracker from '@amplitude/analytics-browser';
import Button from '~/components/elements/Button';
import ProductCardBadges from '~/components/catalog/productCard/Badges';
import ProductCardPosters from '~/components/catalog/productCard/Posters';
import CustomImage from '~/components/elements/CustomImage';
import amplitude from '~/plugins/amplitude';

export default {
  name: 'ProductCard',
  components: {
    CustomImage,
    ProductCardPosters,
    ProductCardBadges,
    Button,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    pageType: {
      type: String,
      default: '',
    },
    tall: {
      type: Boolean,
      default: false,
    },
    minified: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    breadcrumbsList: {
      type: Array,
      default: () => [],
    },
    catalogType: {
      type: String,
      default: 'catalog',
    },
    eventTitle: {
      type: String,
      default: '',
    },
    eventTabName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    ...mapGetters('cart', [
      'productInCart',
      'getProductCount',
      'getProductCountWithBundle',
    ]),
    ...mapGetters('catalog', ['formattedAllCategories']),
    ...mapGetters('favorites', [
      'productInFavorites',
      'getFavoritesCount',
    ]),
    ...mapState('auth', ['hasSession']),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
    categoryLink() {
      const categoryCodes = this.item.categoryCodes;
      return (
        categoryCodes &&
        categoryCodes.length > 0 &&
        categoryCodes[categoryCodes.length - 1]
      );
    },
    disabledButtonToCart() {
      return this.getProductCount(this.item.id) >= this.item.stock.qty ||
             this.getProductCountWithBundle(this.item.id) >= this.item.stock.qty;
    },
    isLowQty() {
      return this.item.stock.qty <= 100
    },
    visibleBadges() {
      return this.item.badges?.length;
    },
    visibleColors() {
      return this.item.variantGroups?.characteristics.some(item => item.isColor);
    },
    cardBtn() {
      if (!this.item?.price?.value) {
        return {
          theme: 'simple',
          name: 'Товар закончился',
          icon: '',
          disabled: true,
          onClick: '',
          inCart: false,
        }
      }
      const isInCart = this.productInCart(this.item.id)

      return {
        name: '',
        theme: 'simple',
        disabled: false,
        icon: isInCart ? 'cat-cart-filled' : 'cat-cart',
        onClick: isInCart ? this.goToCart : this.onAddCartButtonClick,
        inCart: isInCart,
      }
    },
    productPosters() {
      return this.item?.media || []
    },
  },
  mounted() {
    amplitude();
  },
  methods: {
    ...mapActions({
      addToCartProduct: 'cart/addToCartProduct',
      changeCartProductItemCount: 'cart/changeCartProductItemCount',
      addFavoritesItem: 'favorites/addFavoritesItem',
      setLastFavoriteAddedItem: 'favorites/setLastFavoriteAddedItem',
      deleteFavoritesItem: 'favorites/deleteFavoritesItem',
      showQuickProduct: 'quickLook/showQuickProduct',
    }),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    onAddCartButtonClick() {
      console.log('onAddCartButtonClick');
      if (this.item.variantGroups) {
        this.showQuickProduct({ code: this.item.code, category: this.categoryLink })
        return
      }

      this.addItemToCart(this.item, this.pageType)
    },
    clickItemEvent() {
      const catalogType = this.catalogType === 'brands' ? 'Бренды' : (this.breadcrumbsList[2]
        ? this.breadcrumbsList[2]?.name
        : 'Каталог'
      );
      const eventProperties = {
        catalog_name: this.breadcrumbsList?.length > 3
          ? this.breadcrumbsList[this.breadcrumbsList?.length - 1]?.name
          : 'Основная страница',
        catalog_type: catalogType,
        catalog_brand: this.catalogType === 'brands' ? this.breadcrumbsList[2]?.name :'',
        products: [{
          item_id: `${this.item.productId}`,
          item_brand: this.item.brandName,
          item_name: this.item.name,
          item_price: this.item.price.value,
          item_discount: this.item.oldPrice
          ? `${Math.round((this.item.oldPrice.value - this.item.price.value) /
            this.item.oldPrice?.value * 100)}%`
          : '',
        }],
      };
      if (this.pageType === 'main') {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'select_item',
          ecommerce: {
            items: [{
              item_name: this.item.name,
              item_id: `${this.item.productId}`,
              price: this.item.price.value,
              discount: '',
              item_brand: this.item.brandName,
              item_category: this.item.categoryName,
              item_list_name: `main ${this.eventTitle} ${this.eventTabName}`,
            }],
          },
        });
        amplitudeTracker.track('click_selection_item', {
        selection_name: `${this.eventTitle} ${this.eventTabName}`,
        products: [{
          item_id: `${this.item.productId}`,
          item_brand: this.item.brandName,
          item_name: this.item.name,
          price: this.item.price.value,
        }],
      });
      } else {
        amplitudeTracker.track('click_item', eventProperties);
      }
    },
    handleGoToCart() {
      this.$router.push({ path: '/cart' });
    },
    addItemToCart(item, pageType) {
      try {
        const rrApi = {
          addToBasket: (id) => {},
        };
        // eslint-disable-next-line no-undef
        rrApi?.addToBasket(item.id);
      } finally {
        this.addToCartProduct(item, pageType);
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'KZT',
            value: item.price.value,
            items: [{
              item_name: item.name,
              item_id: `${item.productId}`,
              price: item.price.value,
              discount: item.oldPrice ? item.oldPrice.value - item.price.value : '',
              item_brand: item.brandName ?? '',
              item_category: item.categoryName ?? '',
              item_list_name: pageType === 'favorites' ? 'Избранное' : 'Каталог',
            }],
          },
        });
        amplitudeTracker.track('add_to_cart', {
          products: [{
            item_id: `${item.productId}`,
            item_brand: item.brandName ?? '',
            item_name: item.name,
            item_category: pageType === 'favorites' ? 'Избранное' : 'Каталог',
            item_category2: item.categoryName ?? '',
            item_price: item.price.value,
            item_discount: item.oldPrice
              ? `${Math.round((item.oldPrice.value - item.price.value) /
                item.oldPrice.value * 100)}%`
              : '',
          }],
        });
      }
    },

    goToCart() {
      this.$router.push({ path: '/cart' })
    },
    goToProduct() {
      this.$router.push({
        name: 'catalog.category.product',
        params: {
          category: this.categoryLink,
          product: this.item.code,
        },
      })
    },
    styleColor(props) {
      const characteristics = this.item.variantGroups.characteristics;
      let color;
      props.forEach(itemProp => {
        const codeItem = itemProp.split('#')[0];
        const characteristicItem = characteristics.find(item => item.code === codeItem && item.isColor);
        if (characteristicItem) {
          color = itemProp.split('#')[1];
        }
      });

      return {
        backgroundColor: `#${color}`,
      };
    },
    toggleToFavoritesItem() {
      if (this.hasSession) {
        if (this.productInFavorites(this.item.productId)) {
          this.deleteFavoritesItem(this.item.productId);
        } else {
          this.addFavoritesItem(this.item.productId);
          this.setLastFavoriteAddedItem({
            name: this.item.name,
            brandName: this.item.brandName,
            imgSrc: this.item.image.url.small,
          });
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'add_to_wishlist',
            ecommerce: {
              currency: 'KZT',
              value: this.item.price.value.from ? this.item.price.value.from : this.item.price.value,
              items: [{
                item_name: this.item.name,
                item_id: `${this.item.productId}`,
                price:  this.item.price.value.from ? this.item.price.value.from : this.item.price.value,
                discount: this.item.oldPrice ? this.item.oldPrice.value - this.item.price.value : '',
                item_brand: this.item.brandName ?? '',
                item_category: this.item.categoryName ?? '',
                item_list_name: 'Каталог',
              }],
            },
          });
          amplitudeTracker.track('add_to_wishlist', {
            products: [{
              item_id: `${this.item.productId}`,
              item_brand: this.item.brandName?? '',
              item_name: this.item.name,
              item_category: 'Каталог',
              item_category2: this.item.categoryName ?? '',
              price:  this.item.price.value.from ? this.item.price.value.from : this.item.price.value,
              item_discount: this.item.oldPrice
                ? `${Math.round((this.item.oldPrice.value - this.item.price.value) /
                  this.item.oldPrice.value * 100)}%`
                : '',
            }],
          });
        }
        this.$emit('toggle-favorite', this.item.productId);
      } else {
        this.setIsOpenedAuthModal(true);
        amplitudeTracker.track('loginform_start', { page: 'catalog' });
      }
    },
    removeFromPromo() {
      this.$emit('remove-item-from-promo', this.item.productId)
    },
  },
};
</script>
